<template>
  <div class="container">
    <div class="main_container">
      <el-row class="status" v-for="(item, index ) in statusList" :key="index">
        <el-col :sm="2" :md="2" :lg="2" :xl="2" class="age">{{item.date}}</el-col>
        <el-col class="hidden-xs-only" :sm="2" :md="2" :lg="2" :xl="2"
          >&nbsp;</el-col
        >
        <el-col :sm="20" :md="20" :lg="20" :xl="20" class="news">
          <el-collapse accordion>
            <div v-for="itemChild in item.list" :key="itemChild.id">
              <el-collapse-item>
              <template slot="title">
                <div class="news_title">
                  <el-col :sm="6" :md="6" :lg="6" :xl="6" class="new_date">
                    <el-col
                      :xs="2"
                      :sm="5"
                      :md="5"
                      :lg="5"
                      :xl="5"
                      class="date_circle"
                      >●</el-col
                    >
                    <el-col
                      :xs="22"
                      :sm="19"
                      :md="19"
                      :lg="19"
                      :xl="19"
                      class="date_style"
                      >{{itemChild.set_time.split(' ')[0]}}</el-col
                    >
                  </el-col>
                  <el-col :sm="18" :md="18" :lg="18" :xl="18" class="date_word"
                    >{{
                      language == 'cn'
                        ? itemChild.cn_title
                        : language == 'jp'
                        ? itemChild.jp_title
                        : language == 'en'
                        ? itemChild.en_title
                        : ''
                    }}</el-col
                  >
                </div>
              </template>
              <div class="detail">
                {{
                      language == 'cn'
                        ? itemChild.cn_content
                        : language == 'jp'
                        ? itemChild.jp_content
                        : language == 'en'
                        ? itemChild.en_content
                        : ''
                    }}
              </div>
            </el-collapse-item>
            </div>
          </el-collapse>
        </el-col>
      </el-row>
      <el-row class="page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="statusForm.page"
          :page-size="statusForm.limit"
          layout="prev, pager, next"
          :total="totalNum"
        >
        </el-pagination>
      </el-row>
    </div>
  </div>
</template>

<script>
import { homeResource } from '../api/index'
export default {
  name: 'status',
  props: {},
  data() {
    return {
      language: window.sessionStorage.getItem('language')?window.sessionStorage.getItem('language'): 'jp',
        statusList:[],
        totalNum: 0,
        statusForm: {
          type: 4,
          page: 1,
          limit: 10
        }
    }
  },
  computed: {},
  created() {
    this.getStatusList()
  },
  mounted() {},
  watch: {},
  methods: {
    getStatusList() {
      homeResource(this.statusForm)
        .then(res => {
          if (res.code == '20000') {
            this.statusList = res.data.list
            this.totalNum = Number(res.data.count)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
      handleCurrentChange(val) {
        this.statusForm.page = val
        this.getStatusList()
      }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
@media screen and(min-width: 768px) {
  .container {
    width: 100%;
    .main_container {
      width: 70%;
      margin: 0 auto;
      padding-top: 133px; //nav高132px
      .status {
        margin-bottom: 50px;
        width: 100%;
        display: flex;
        .age {
          height: 57px;
          color: #bc3810;
          font-size: 24px;
          font-weight: 500;
          line-height: 57px;
        }
        .news {
          .news_title {
            width: 100%;
            .new_date {
              display: flex;
              .date_circle {
                color: #bc3810;
              }
              .date_style {
                color: #000000;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 1.13px;
              }
            }
            .date_word {
              color: #000000;
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 1.13px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .detail {
            color: #000000;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.88px;
            line-height: 38px;
            padding-left: 25%;
          }
        }
      }
      .page {
        text-align: center;
        margin: 0 0 30px 0;
      }
    }
  }
}
@media screen and(max-width: 767px) {
  .container {
    width: 100%;
    .main_container {
      width: 80%;
      margin: 0 auto;
      padding-top: 76px;
      .status {
        margin-bottom: 15px;
        width: 100%;
        .age {
          height: 38px;
          color: #bc3810;
          font-size: 16px;
          font-weight: 500;
          line-height: 38px;
        }
        .news {
          .news_title {
            width: 100%;
            display: flex;
            flex-direction: column;
            .new_date {
              display: flex;
              height: 38px;
              line-height: 38px;
              .date_circle {
                color: #bc3810;
              }
              .date_style {
                color: #000000;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.75px;
              }
            }
          }
          .date_word {
            padding: 0 10px 7px 0;
            color: #000000;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.75px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .detail {
            color: #000000;
            font-size: 12px;
            letter-spacing: 0.75px;
          }
        }
      }
      .page {
        text-align: center;
        margin: 0 0 30px 0;
      }
    }
  }
}
</style>
